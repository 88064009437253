const routes = [
  { path: "/", redirect: { path: "/home" } },
  {
    path: "/",
    component: () => import("layouts/AuthLayout.vue"),
    name: "auth-layout",
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("pages/Login/Index.vue"),
      },
      {
        path: "/reset",
        name: "reset",
        component: () => import("pages/Login/Reset.vue"),
      },
      {
        path: "/password",
        name: "password",
        props: true,
        component: () => import("pages/AlterarSenha/Index.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("layouts/MainLayout.vue"),
    name: "main-layout",
    children: [
      {
        path: "/home",
        name: "home",
        meta: { title: "Home" },
        component: () => import("pages/Index.vue"),
      },
      {
        path: "/links",
        name: "links",
        meta: { title: "Nossas bios" },
        component: () => import("src/pages/Links/Index.vue"),
      },
      {
        path: "link/:id?",
        name: "link",
        meta: { title: "Edição de tema" },
        component: () => import("src/pages/Links/Link.vue"),
      },
      {
        path: "/acessos",
        name: "acessos",
        meta: { title: "Acessos" },
        component: () => import("src/pages/Acessos/Index.vue"),
      },
      {
        path: "/categorias",
        name: "categorias",
        meta: { title: "Categorias" },
        component: () => import("src/pages/Categorias/Index.vue"),
      },
      {
        path: "/posts",
        name: "posts",
        meta: { title: "Posts" },
        component: () => import("src/pages/Posts/Index.vue"),
      },
      {
        path: "dashboard/:id?",
        name: "dashboard",
        meta: { title: "Dashboard" },
        component: () => import("src/pages/Acessos/Dashboard.vue"),
      },
      {
        path: "clientes",
        name: "clientes",
        meta: { title: "Clientes" },
        component: () => import("src/pages/Clientes/Index.vue"),
      },
      {
        path: "cliente/:id?",
        name: "cliente",
        meta: { title: "Edição de cliente" },
        component: () => import("src/pages/Clientes/Cliente.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("layouts/PublicLayout.vue"),
    children: [
      {
        path: "biolink/:name",
        name: "biolink",
        props: true,
        meta: { title: "My Link" },
        component: () => import("src/pages/Biolink.vue"),
      },
    ],
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("pages/Error404.vue"),
  },
];

export default routes;
