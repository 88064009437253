<template>
  <div style="background-color: #f2f4f7">
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "App",
  data() {
    return {
      reloaded: false,
    };
  },
  mounted() {
 
  },
  methods: {

  },
});
</script>
